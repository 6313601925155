<template>
  <div class="py-4 px-12 list-mdt">
    <span class="flex text-blue-900 text-2xl font-semibold mb-6">{{ $t("multidisciplinary_board.lbl_meeting_title")
      }}</span>
    <!-- body -->
    <div class="row mt-4 body-list">
      <div class="flex items-center mb-4">
        <div class="flex items-center border bg-white rounded-md border-grey-300 p-2 mr-2 w-60">
          <IconSearch class="cursor-pointer" />
          <input v-model="valueSearch" @keypress.enter="() => getMeetRooms({ page_num: 1 })"
            class="border-0 txt-grey-500 ml-2 focus:outline-none"
            :placeholder="$t('lbl_enter_the_meeting_room_name')" />
        </div>
        <div class="flex items-center rounded-md mr-2 w-60">
          <div class="input-group input-search-box">
            <select class="form-select text-blue-900 robo-14-400" aria-label="Default select example"
              v-model="roomFilter">
              <option :class="roomFilter == roomOptions.all ? 'text-blue-900 font-weight-bold bg-f2' : 'txt-black'"
                :value="roomOptions.all">{{ $t('lbl_all_meeting_rooms') }}</option>
              <option class="robo-14-400"
                :class="roomFilter == roomOptions.oneTime ? 'text-blue-900 font-weight-bold bg-f2' : 'txt-black'"
                :value="roomOptions.oneTime">{{ $t('lbl_one_time_meeting_room') }}</option>
              <option class="robo-14-400"
                :class="roomFilter == roomOptions.day ? 'text-blue-900 font-weight-bold bg-f2' : 'txt-black'"
                :value="roomOptions.day">{{ $t('lbl_daily_meeting_room') }}</option>
              <option class="robo-14-400"
                :class="roomFilter == roomOptions.week ? 'text-blue-900 font-weight-bold bg-f2' : 'txt-black'"
                :value="roomOptions.week">{{ $t('lbl_weekly_meeting_room') }}</option>
            </select>
          </div>
        </div>
      </div>

      <!-- left -->
      <div class="col-md-6" v-loading="isLoading">
        <div class="w-100" v-if="meet_rooms &&
      meet_rooms.data &&
      meet_rooms.data.length &&
      !loading
      ">
          <div class="d-flex justify-content-between mb-3">
            <a href="javascript:;" class="text-blue-900" @click="showModalConcludings(true)">
              {{ $t("multidisciplinary_board.lbl_meet_report") }}({{
      meet_concludings ? meet_concludings.page.total : 0
    }})
            </a>
          </div>
          <div v-for="item in meet_rooms.data" :key="item.id" @click="onShowMeetRoom(item.id)">
            <div class="room-item cursor-pointer md:flex p-6"
              :class="showing_meet_room_id == item.id ? 'active-item' : ''">
              <p :class="showing_meet_room_id == item.id ? 'tex-white' : 'txt-grey-600'
      " class="robo-16-400 mb-2 max-line-1 min-w-48 mr-2">{{ mdtUtils.getTimeStringDetail(item) }}</p>
              <div :class="showing_meet_room_id == item.id ? 'tex-white' : 'txt-black'
      " class="robo-18-500 mb-1">
                <div :class="showing_meet_room_id == item.id ? 'tex-white' : 'txt-black'
      " class="max-line-1">{{ item.name }}</div>
                <div class="text-blue-900 text-sm">{{ item.clinic && `(${item.clinic.name})` }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mb-2 flex-col xl:flex-row justify-between items-center">
          <div class="fs-16 sm:mb-2 xl:mb-0">{{ $t('fm_pagination', { countItems, totalItems }) }}</div>
          <el-pagination background layout="prev, pager, next" :pager-count="5" :page-size="DEFAULT_SIZE_OF_PAGE"
            :total="totalItems" class="el-pagination-mdt" @next-click="handleChangePage" @prev-click="handleChangePage"
            @current-change="handleChangePage" hide-on-single-page></el-pagination>
        </div>
      </div>
      <!-- right -->
      <div class="text-center col-md-6">
        <img src="../../../public/assets/images/MDT/Frame.png" class="img-1 mb-3" alt />
        <p class="text-center robo-24-500 txt-black fw-700">{{ $t("multidisciplinary_board.lbl_desc_title") }}</p>
        <p class="text-center robo-14-400 txt-black">{{ $t("multidisciplinary_board.lbl_desc_content") }}</p>
        <div class="dropdown">
          <button class="btn bg-pri bd-pri text-white mt-3" @click="goToCreate">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M3.25 8.29008C3.25 6.75108 3.51546 5.96528 3.95033 5.53041C4.3852 5.09553 5.171 4.83008 6.71 4.83008H13.03C14.569 4.83008 15.3548 5.09553 15.7897 5.53041C16.2245 5.96528 16.49 6.75108 16.49 8.29008V16.7101C16.49 18.2476 16.2226 19.0339 15.7864 19.4692C15.4522 19.8028 14.9128 20.0364 13.9902 20.1276C13.7662 20.6803 13.4737 21.1979 13.1231 21.6698C14.6974 21.6598 15.982 21.3933 16.8461 20.5309C17.7274 19.6512 17.99 18.3325 17.99 16.7101V8.29008C17.99 6.66908 17.7305 5.34987 16.8503 4.46975C15.9702 3.58962 14.651 3.33008 13.03 3.33008H6.71C5.089 3.33008 3.7698 3.58962 2.88967 4.46975C2.00954 5.34987 1.75 6.66908 1.75 8.29008V13.5068C2.16586 12.9091 2.67252 12.3794 3.25 11.9375V8.29008Z"
                fill="white" />
              <path
                d="M20.02 17.6001L17.24 15.6501V9.34013L20.02 7.39013C21.38 6.44013 22.5 7.02013 22.5 8.69013V16.3101C22.5 17.9801 21.38 18.5601 20.02 17.6001Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M13 9.5C13.8284 9.5 14.5 8.82843 14.5 8C14.5 7.17157 13.8284 6.5 13 6.5C12.1716 6.5 11.5 7.17157 11.5 8C11.5 8.82843 12.1716 9.5 13 9.5Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <circle cx="7.5" cy="17.5" r="5.25" stroke="white" stroke-width="1.5" />
              <path d="M7.5 14.5V20.5" stroke="white" stroke-width="1.5" />
              <path d="M10.5 17.5L4.5 17.5" stroke="white" stroke-width="1.5" />
            </svg>
            {{
      $t("multidisciplinary_board.btn_add_new_meet")
    }}
          </button>
        </div>
      </div>
    </div>
    <!-- end body -->
    <div class="loading" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <MeetRoomDetail v-show="showing_meet_room" :showing_room="showing_meet_room" :loading_room="loading_room"
      @refresh="getRooms" @hideMeetRoomDetail="hideMeetRoomDetail" />
    <div class="modal fade" id="modalConcludingList">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="d-flex justify-content-between p-3">
            <h5 class="modal-title" id="modalConcludingList">{{ $t("multidisciplinary_board.lbl_list_reports") }}</h5>
            <button type="button" class="close bg-white border-0" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="robo-18-500">&times;</span>
            </button>
          </div>
          <div class="modal-body p-3">
            <div v-for="conc in meet_concludings.data" :key="conc.id" class="w-100 mb-2 bg--babyblue p-1 cursor-pointer"
              @click="openMeetConcluding(conc)">
              <a href="javascript:;" class="w-100">
                {{ $t("multidisciplinary_board.lbl_report") }}
                {{ conc.meet_room ? conc.meet_room.name : "" }}
                {{ $t("multidisciplinary_board.lbl_date") }}
                {{ formatDMY(conc.meet_time) }}
              </a>
            </div>
            <div class="text-center my-3" v-if="!meet_concludings || !meet_concludings.data">{{
      $t("multidisciplinary_board.lbl_blank_report") }}</div>
            <div class="text-center my-3">
              <a href="javascript" v-if="(meet_concludings.page && meet_concludings.page.page_num) < (meet_concludings.page && meet_concludings.page.total_page)
      " @click="getMeetRoomConcludings(true)">{{ $t("multidisciplinary_board.btn_more") }}</a>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'
import MeetRoomDetail from '../../components/Meets/MeetRoomDetail.vue'
import { IconSearch } from '@/components/Icon'
import { debounce } from 'lodash'

export default {
  name: 'MeetingList',
  components: {
    IconSearch,
    MeetRoomDetail
  },
  data () {
    const ROOM_OPTIONS = {
      oneTime: 0,
      day: 1,
      week: 2,
      all: 3
    }

    const DEFAULT_SIZE_OF_PAGE = 6

    return {
      meet_rooms: [],
      page: 1,
      loading: false,
      loading_room: false,
      showing_meet_room_id: null,
      showing_meet_room: null,
      doctor_id: null,
      dtr_member: null,
      meet_concludings: {},
      roomFilter: ROOM_OPTIONS.day,
      roomOptions: ROOM_OPTIONS,
      totalItems: null,
      valueSearch: '',
      DEFAULT_SIZE_OF_PAGE,
      mdtUtils,
      isLoading: false
    }
  },
  async created () {
    await this.getRooms()
  },
  mounted () {
    this.doctor_id = appUtils.getLocalUser()?.doctor?.id

    if (this.$route.query.meetid) {
      this.onShowMeetRoom(this.$route.query.meetid)
      setTimeout(() => {
        let query = Object.assign({}, this.$route.query)
        delete query.meetid
        this.$router.replace({ query })
      }, 100)
    }
  },
  watch: {
    propertyPageAndRoomFilter (value) {
      const [page, roomFilter] = value.split(' ')
      if (page || roomFilter) {
        this.getMeetRooms()
      }
    }
  },
  computed: {
    countItems () {
      return this.page * 6 > this.totalItems ? this.totalItems : this.page * 6
    },
    propertyPageAndRoomFilter () {
      return `${this.page} ${this.roomFilter}`
    }
  },
  methods: {
    async getRooms () {
      try {
        this.loading = true
        await this.getMeetRooms()
        await this.getMeetRoomConcludings()
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async getMeetRooms (data) {
      this.isLoading = true
      let self = this
      let params = {
        page_num: self.page,
        page_size: self.DEFAULT_SIZE_OF_PAGE,
        ...data
        // clinic_id: 1
      }
      if (this.valueSearch) {
        params.keyword = this.valueSearch
      }

      if (Number(this.roomFilter) !== this.roomOptions.all) {
        params.repeat_type = Number(this.roomFilter)
      }

      await self.$rf
        .getRequest('DoctorRequest')
        .getMeetRoomsV2(params)
        .then(r => {
          self.meet_rooms = r?.data
          self.totalItems = r.data?.page?.total || 0
          this.isLoading = false
        })
        .finally(() => {
          this.isLoading = false
          return true
        })
    },
    async getMeetRoomConcludings (is_next = false) {
      let self = this
      if (!is_next) {
        self.meet_concludings = {}
      }
      let params = {
        page_size: 10,
        page_num: self.meet_concludings?.page?.page_num
          ? self.meet_concludings?.page?.page_num + 1
          : 1,
        sort_by: 'id',
        order: 'desc'
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getMeetRoomConcludingsV2(params)
        .then(res => {
          if (!is_next) {
            self.meet_concludings = res.data
          } else {
            self.meet_concludings = {
              ...self.meet_concludings,
              page: {
                ...self.meet_concludings?.page,
                page_num: res.data?.page?.page_num
              }
            }
            if (res.data?.page?.total) {
              let old = self.meet_concludings?.data
              let newObj = res.data?.data
              self.meet_concludings.data = old.concat(newObj)
            }
          }
        })
    },
    onShowMeetRoom (id) {
      this.showMeetRoom(id)
    },
    async showMeetRoom (id) {
      let self = this
      self.showing_meet_room_id = id
      self.loading_room = true
      self.$rf
        .getRequest('DoctorRequest')
        .showMeetRoom(id)
        .then(r => {
          self.showing_meet_room = r.data
        })
        .finally(() => {
          self.loading_room = false
        })
    },
    goToCreate () {
      this.$router.push({ name: 'CreateMeet' })
    },
    hideMeetRoomDetail () {
      this.showing_meet_room = null
      this.showing_meet_room_id = null
    },
    formatDMY (d) {
      if (!d) return
      return window.moment(d).format('DD-MM-YYYY')
    },
    showModalConcludings (show) {
      window.$('#modalConcludingList').modal(show ? 'show' : 'hide')
    },
    openMeetConcluding (c) {
      window.open(c.pdf)
    },
    handleChangePage: debounce(function (newPage) {
      this.page = newPage
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .room-item {
    margin-bottom: 16px;
    border-radius: 10px;
    width: 100%;
    background: #fff;
    box-shadow: 0 8px 8px 0 rgba(196, 196, 196, 0.11),
      0 12px 18px 0 rgba(184, 184, 184, 0.11);
  }

  .active-item {
    background-color: #20419b;
    color: white;
  }

  .mdt-room-detail {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1041;
  }

  .mdt-room-detail ._header {
    display: flex;
    justify-content: space-between;
  }

  .mdt-room-detail .content-room {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    padding-left: 32px;
    padding-right: 0;
    padding-top: 16px;
    background: #fff;
    height: 100vh;
  }

  .body-list {
    min-height: 500px;
  }

  .img-1 {
    width: 100%;
    max-width: 420px;
    height: auto;
    margin: 0 auto;
  }

  .border-bottom-2 {
    border-bottom: 2px solid #20419b !important;
  }

  .min-w-48 {
    min-width: 12rem;
  }

  .loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  .spinner-border {
    width: 3rem;
    height: 3rem;
  }

  .input-search-box span,
  .input-search-box input {
    background-color: inherit !important;
  }

  .input-search-box {
    border: 1.0625px solid #c3cae7;
    max-width: 100%;
  }

  .bg-bold-item {
    background-color: #e7eaf5 !important;
  }

  .form-control {
    height: 40px;
  }

  .form-select {
    background-image: url(../../../public/assets/images/icon/sort-icon.svg);
    background-size: 24px;
    background-position: right 0.25rem center;
  }

  .el-pagination-mdt {
    padding: 0;

    & .number.active {
      background-color: #20419b !important;
    }
  }
}
</style>
