<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" id="modal--add-employee">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-3">
          <div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <p class="robo-20-500 mb-0">{{$t('lbl_add_staff_to_the_organization')}}</p>
              <img
                src="../../../public/assets/images/icon/x-gray.svg"
                alt
                class="cursor-pointer"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div class="row mt-2">
              <div class="col-sm-12">
                <div style="padding: 15px 15px;">
                  <div class="row">
                    <div class="form-group mb-3">
                      <div class="row">
                        <div class="col-sm-6">
                          <label
                            class="fs-16 fw-500 txt-grey-900 required"
                          >{{ $t('lbl_full_name') }}</label>
                        </div>
                        <input
                          class="form-control form-input-login mt-2"
                          :placeholder="$t('lbl_enter_full_name')"
                          v-model="form.name"
                        />
                      </div>
                      <small
                        v-if="errorFields.name && isErrorValid"
                        class="text-warning"
                      >{{ errorFields.name }}</small>
                    </div>
                    <div class="form-group mb-3">
                      <div class="row">
                        <div class="col-sm-6">
                          <label class="fs-16 fw-500 txt-grey-900 required">Email</label>
                        </div>
                        <input
                          class="form-control form-input-login mt-2"
                          :placeholder="$t('lbl_enter_email')"
                          type="email"
                          v-model="form.email"
                        />
                      </div>
                      <small
                        v-if="errorFields.email && isErrorValid"
                        class="text-warning"
                      >{{ errorFields.email }}</small>
                    </div>
                    <div class="form-group mb-3">
                      <div class="row">
                        <div class="col-sm-6">
                          <label class="fs-16 fw-500 txt-grey-900 required">{{ $t('lbl_position') }}</label>
                        </div>
                        <input
                          class="form-control form-input-login mt-2"
                          :placeholder="$t('lbl_enter_position')"
                          v-model="form.position"
                        />
                      </div>
                      <small
                        v-if="errorFields.position && isErrorValid"
                        class="text-warning"
                      >{{ errorFields.position }}</small>
                    </div>
                    <div class="form-group mb-3">
                      <div class="row">
                        <div class="col-sm-6">
                          <label
                            class="fs-16 fw-500 txt-grey-900 required"
                          >{{ $t('lbl_job_title') }}</label>
                        </div>
                        <select
                          v-model="form.type"
                          class="form-control form-input fs-16 fw-500 form-select mt-2"
                        >
                          <option :value="0">{{ $t('lbl_doctor') }}</option>
                          <option :value="1">{{ $t('lbl_healthcare_professional') }}</option>
                          <option :value="2">{{ $t('lbl_specialist_doctor') }}</option>
                          <option :value="3">{{ $t('lbl_nurse') }}</option>
                          <option :value="4">{{ $t('lbl_other') }}</option>
                        </select>
                      </div>
                      <small
                        v-if="errorFields.type && isErrorValid"
                        class="text-warning"
                      >{{ errorFields.type }}</small>
                    </div>
                    <div class="form-group mb-3">
                      <div class="row">
                        <div class="col-sm-6">
                          <label
                            class="fs-16 fw-500 txt-grey-900 required"
                          >{{ $t('lbl_permission_role') }}</label>
                        </div>
                        <select
                          v-model="form.role"
                          :disabled="isAddAdmin"
                          class="form-control form-input fs-16 fw-500 form-select mt-2"
                        >
                          <!-- <option :value="1">Chủ quản</option> -->
                          <option :value="2">{{ $t('lbl_admin') }}</option>
                          <option :value="3">{{ $t('lbl_member') }}</option>
                        </select>
                      </div>
                      <small
                        v-if="errorFields.role && isErrorValid"
                        class="text-warning"
                      >{{ errorFields.role }}</small>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-6">
                          <label class="fs-16 fw-500 txt-grey-900">{{ $t('lbl_hotline') }}</label>
                        </div>
                        <input
                          class="form-control form-input-login mt-2"
                          :placeholder="$t('lbl_phone_number')"
                          v-model="form.phoneNumber"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer text-right">
            <button
              v-if="can('create-new-patient')"
              :disabled="is_submit"
              class="btn-action btn bg-pri bd-pri text-white"
              @click="postOrgMembersV2"
            >
              <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="is_submit"></span>
              <span>{{ $t('lbl_send') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinValidationForms } from '../../utils/mixinValidationForms'

export default {
  name: 'ModalAddEmployee',
  props: ['isAddAdmin'],
  mixins: [mixinValidationForms],
  data () {
    const initialForm = {
      position: '',
      name: '',
      type: 0,
      role: 2,
      email: '',
      org_id: this.$globalOrg?.id || this.$user?.members[0].org_id || null,
      phoneNumber: ''
    }
    return {
      initialForm,
      form: { ...initialForm },
      is_submit: false,
      ignoreFields: ['phoneNumber']
    }
  },
  beforeMount () {
    this.handleResetErrors(this.form, this.errorFields, this.ignoreFields)
  },
  watch: {
    isAddAdmin () {
      // if (this.isAddAdmin) this.role = 2
    }
  },
  mounted () {
    const self = this
    self.form.org_id = this.$globalOrg?.id || this.$user?.members[0].org_id

    window.$('#modal--add-employee').on('hide.bs.modal', function (e) {
      self.form = { ...self.initialForm }
    })
  },
  methods: {
    async postOrgMembersV2 () {
      this.is_submit = true
      this.handleResetErrors(this.form, this.errorFields, this.ignoreFields)

      if (this.form.email === this.$user?.email) {
        this.$message({
          type: 'warning',
          message: 'Bạn đã có trong danh sách thành viên'
        })
        this.is_submit = false
        this.form.email = ''
        return
      }

      try {
        this.isErrorValid = this.handleValidationForms(
          this.form,
          this.errorFields,
          this.ignoreFields
        )

        if (this.isErrorValid) {
          return
        }

        const params = {
          ...this.form
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .postOrgMembersV2(params)
          .then(res => {
            this.$emit('reload')
            this.form = { ...this.initialForm }
          })
          .finally(() => {
            this.is_submit = false
            window.$('#modal--add-employee').modal('hide')
          })
      } catch (error) {
        this.is_submit = false
        console.log(error)
      } finally {
        this.is_submit = false
      }
    }
  }
}
</script>
