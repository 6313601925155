<template>
  <div class="p-3 pb-5 mb-5">
    <div class="d-flex align-items-center">
      <svg
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mr-2"
      >
        <path
          d="M32.295 11.475C32.067 11.3433 31.8083 11.274 31.545 11.274C31.2817 11.274 31.023 11.3433 30.795 11.475L25.5 14.085C25.4573 12.9204 24.9644 11.8177 24.125 11.0092C23.2857 10.2006 22.1654 9.74922 21 9.75H7.5C6.30653 9.75 5.16193 10.2241 4.31802 11.068C3.47411 11.9119 3 13.0565 3 14.25V23.25C3 24.4435 3.47411 25.5881 4.31802 26.432C5.16193 27.2759 6.30653 27.75 7.5 27.75H21C22.1654 27.7508 23.2857 27.2994 24.125 26.4908C24.9644 25.6823 25.4573 24.5796 25.5 23.415L30.84 26.085C31.0445 26.1898 31.2703 26.2463 31.5 26.25C31.7808 26.2509 32.0563 26.1729 32.295 26.025C32.5112 25.8899 32.6893 25.7019 32.8127 25.4788C32.936 25.2558 33.0005 25.0049 33 24.75V12.75C33.0005 12.4951 32.936 12.2442 32.8127 12.0212C32.6893 11.7981 32.5112 11.6101 32.295 11.475ZM22.5 23.25C22.5 23.6478 22.342 24.0294 22.0607 24.3107C21.7794 24.592 21.3978 24.75 21 24.75H7.5C7.10218 24.75 6.72064 24.592 6.43934 24.3107C6.15804 24.0294 6 23.6478 6 23.25V14.25C6 13.8522 6.15804 13.4706 6.43934 13.1893C6.72064 12.908 7.10218 12.75 7.5 12.75H21C21.3978 12.75 21.7794 12.908 22.0607 13.1893C22.342 13.4706 22.5 13.8522 22.5 14.25V23.25ZM30 22.32L25.5 20.07V17.43L30 15.18V22.32Z"
          fill="#20409B"
        />
      </svg>

      <p class="robo-24-500 txt-pri mb-0">{{ $t("lbl_meeting_room") }}</p>
    </div>
    <div class="my-4">
      <p class="robo-24-500 txt-black">{{ $t("lbl_creat_new_meeting") }}</p>
      <div
        class="d-flex align-items-center txt-grey-600 cursor-pointer"
        @click="goToList"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2"
        >
          <path
            d="M16.2541 4.24106C16.5522 4.53326 16.5793 4.99051 16.3354 5.31272L16.2541 5.40503L9.52658 12L16.2541 18.595C16.5522 18.8872 16.5793 19.3444 16.3354 19.6666L16.2541 19.7589C15.956 20.0511 15.4896 20.0777 15.161 19.8386L15.0668 19.7589L7.7459 12.582C7.44784 12.2898 7.42074 11.8325 7.66461 11.5103L7.7459 11.418L15.0668 4.24106C15.3947 3.91965 15.9262 3.91965 16.2541 4.24106Z"
            fill="#616673"
          />
        </svg>
        <span>{{ $t("lbl_back_to_list") }}</span>
      </div>
    </div>
    <div class="mb-5">
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 required mt-1 txt-black">{{
            $t("lbl_room_name")
          }}</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <input
            type="text"
            class="form-control border radius-10"
            v-model="form.name"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 mt-1 txt-black">{{
            $t("lbl_description")
          }}</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <EditorInput
            id="description-meet"
            :prop_text="form.description"
            @onChangeContent="(content) => (form.description = content)"
          />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 required mt-1 txt-black">{{
            $t("lbl_time")
          }}</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <div class="row">
            <div class="col-md-6 d-flex">
              <input
                type="date"
                class="form-control w-50 border radius-10 mr-1"
                v-model="form.date"
                :min="today"
              />
              <input
                type="time"
                class="form-control w-50 border radius-10"
                v-model="form.start_time"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 mt-1 txt-black">{{
            $t("lbl_repeat")
          }}</label>
        </div>
        <div class="col-md-6">
          <select
            v-model="form.repeat_type"
            class="form-select border radius-10"
          >
            <option :value="0">{{ $t("lbl_not_repeat") }}</option>
            <option :value="1">{{ $t("lbl_daily") }}</option>
            <option :value="2">{{ $t("lbl_weekly") }}</option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 mt-1 txt-black">{{
            $t("lbl_location")
          }}</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <input
            type="text"
            class="form-control border radius-10"
            v-model="form.location"
          />
        </div>
      </div>
      <!-- <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 mt-1 txt-black">Mật khẩu</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <input
            type="text"
            class="form-control border radius-10"
            v-model="form.password"
          >
        </div>
      </div>-->
      <div class="d-flex justify-content-end align-items-center py-3">
        <button
          type="button"
          class="btn btn-white bd-pri txt-pri px-5 mr-2"
          @click="goToList"
        >
          {{ $t("lbl_cancel") }}
        </button>
        <button
          type="button"
          class="btn bg-pri bd-pri text-white px-5"
          @click="onSubmit"
        >
          {{ $t("lbl_save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import EditorInput from '@/components/EditorInput.vue'

export default {
  name: 'CreateMeet',
  components: { EditorInput },
  data () {
    return {
      form: {
        name: '',
        date: '',
        start_time: null,
        end_time: '23:59:59',
        repeat_type: 0,
        location: '',
        password: '',
        description: ''
      },
      today: window.moment().format('YYYY-MM-DD')
    }
  },
  mounted () {
    this.form.date = this.today
  },
  methods: {
    goToList () {
      this.$router.push({ name: 'Meeting' })
    },
    async onSubmit () {
      let self = this
      let form = {
        name: this.form.name,
        date: this.form.date,
        start_time: this.form.start_time,
        end_time: this.form.end_time,
        repeat_type: this.form.repeat_type,
        location: this.form.location,
        password: this.form.password,
        clinic_id: this.$clinicId,
        description: this.form.description
      }
      if (!form.name) {
        alert('Vui lòng nhập tên cuộc họp')
        return false
      }
      if (!form.date) {
        alert('Vui lòng chọn ngày diễn ra')
        return false
      } else {
        form.date = window.moment(form.date).format('DD-MM-YYYY')
      }
      if (!form.start_time) {
        alert('Vui lòng thời gian bắt đầu họp')
        return false
      }
      if (!form.start_time) {
        delete form.start_time
        delete form.end_time
      } else {
        form.start_time = window
          .moment(form.start_time, 'HH:mm')
          .format('HH:mm:ss')
        form.end_time = window
          .moment(form.end_time, 'HH:mm')
          .format('HH:mm:ss')
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .createMeetRoom(form)
        .then(async (r) => {
          await self.handleUpdate100msMeetRoom(r.data.id)
        })
    },
    async handleUpdate100msMeetRoom (meetId) {
      try {
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .update10mmsMeetRoomById(meetId)
        console.log(res.data)
        this.$router.push({
          name: 'Meeting',
          query: { meetid: meetId }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.form-control {
  background: #fff;
  height: 42px;
  font-size: 16px;
  color: black;
}
</style>
