<template>
  <div>
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="modal--assign-employee"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-3">
          <div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <p class="robo-20-500 mb-0">
                {{ $t("Phân công nhiệm vụ cho nhân sự trong Tổ chức") }}
              </p>
              <img
                src="../../../public/assets/images/icon/x-gray.svg"
                alt
                class="cursor-pointer"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div class="row mt-2">
              <div class="col-sm-12">
                <div style="padding: 15px 15px">
                  <div class="row">
                    <div
                      v-for="(service, index) in service_list"
                      :key="service.id"
                      class="form-group mb-3"
                    >
                      <div class="row">
                        <div class="col-sm-6">
                          <label class="fs-16 fw-500 txt-grey-900 required">{{
                            service.name
                          }}</label>
                        </div>
                        <select
                          v-if="form && form[index]"
                          v-model="form[index].role_id"
                          class="form-control form-input fs-16 fw-500 form-select mt-2"
                        >
                          <option
                            v-for="role in service.roles"
                            :key="role.id"
                            :value="role.id"
                          >
                            {{ role.name }}
                          </option>
                        </select>
                      </div>
                      <small
                        v-if="errorFields.type && isErrorValid"
                        class="text-warning"
                        >{{ errorFields.type }}</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer text-right">
            <button
              v-if="can('create-new-patient')"
              :disabled="is_submit"
              class="btn-action btn bg-pri bd-pri text-white"
              @click="assignRolesOrg"
            >
              <span
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                v-if="is_submit"
              ></span>
              <span>Gửi</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinValidationForms } from '../../utils/mixinValidationForms'

export default {
  name: 'ModalAssignEmployee',
  props: ['count_service', 'org_member_id', 'user_id'],
  mixins: [mixinValidationForms],
  data () {
    return {
      form: [],
      is_submit: false,
      ignoreFields: [],
      org_id: 0,
      service_list: []
    }
  },

  async created () {
    this.org_id = this.$globalOrg?.id || this.$user?.members[0].org_id

    if (this.count_service && this.org_member_id && this.org_id) {
      await this.getOrgServiceList()
      await this.getOrgServiceRoleDetail()
    }
  },
  beforeMount () {
    this.handleResetErrors(this.form, this.errorFields, this.ignoreFields)
  },
  mounted () {
    const self = this

    window.$('#modal--assign-employee').on('hide.bs.modal', function (e) {
      self.form = []
      self.$emit('modalClose')
    })
  },
  methods: {
    async getOrgServiceList () {
      await this.$rf
        .getRequest('DoctorRequest')
        .getOrgServiceList(this.org_id)
        .then((res) => {
          this.service_list = res.data.data
        })
    },
    async getOrgServiceRoleDetail () {
      await this.$rf
        .getRequest('DoctorRequest')
        .getOrgServiceRoleDetail(this.org_member_id)
        .then((res) => {
          let formData = []
          if (res.data.length) {
            this.service_list.forEach((service) => {
              let currentService = res.data.find(
                (r) => r.hodo_service_id === service.hodo_service_id
              )
              formData.push({
                role_id: currentService?.role_id || null,
                organization_hodo_service_id: service.id,
                hodo_service_id: currentService?.hodo_service_id || service.hodo_service_id
              })
            })
          } else {
            this.service_list.forEach((service) => {
              formData.push({
                role_id: null,
                organization_hodo_service_id: service.id,
                hodo_service_id: service.hodo_service_id
              })
            })
          }
          this.form = formData
        })
    },
    async assignRolesOrg () {
      this.is_submit = true
      this.handleResetErrors(this.form, this.errorFields, this.ignoreFields)
      try {
        this.isErrorValid = this.handleValidationForms(
          this.form,
          this.errorFields,
          this.ignoreFields
        )

        if (this.isErrorValid) {
          return
        }

        const params = {
          organization_member_id: this.org_member_id,
          user_id: this.user_id,
          org_hodo_services: this.form
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .assignRolesOrg(params)
          .then(() => {
            this.$emit('reload')
            this.$toast.open({
              message: 'Thay đổi quyền hạn thành công',
              type: 'success'
            })
          })
          .finally(() => {
            window.$('#modal--assign-employee').modal('hide')
          })
      } catch (error) {
        this.is_submit = false
        console.log(error)
      } finally {
        this.is_submit = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-dialog {
  max-width: 50%;
}
</style>