<template>
  <div
    class="modal fade"
    id="modalAddMember"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalAddMemberLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="d-flex justify-content-between p-3">
          <h5 class="modal-title" id="modalAddMemberLabel">
            Mời Bác sĩ tham gia hội chẩn
          </h5>
          <button
            type="button"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="robo-18-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <p class="robo-18-400 txt-grey-600">Bác sĩ</p>
            <v-select
              v-if="doctors"
              class="mt-2 txt-black bg-white border radius-10 border-2"
              :options="filteredAndSorted"
              label="name"
              placeholder="Chọn bác sĩ"
              :filterable="false"
              v-model="selected_doctor"
              transition=""
              @search="searchDoctor"
            >
              <div slot="no-options">Not found!</div>
              <template v-slot:option="item">
                <div class="d-flex align-items-center">
                  <p class="mb-0 robo-14-500 mt-1">{{ item.name }}</p>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="ml-2"
                    v-if="item.circle"
                  >
                    <path
                      d="M6.58579 9.41452C7.36684 10.1956 8.63316 10.1956 9.41421 9.41452C10.1953 8.63347 10.1953 7.36714 9.41421 6.58609C8.63316 5.80504 7.36684 5.80504 6.58579 6.58609C5.80474 7.36714 5.80474 8.63347 6.58579 9.41452Z"
                      fill="#20419B"
                    />
                    <ellipse
                      cx="2.66682"
                      cy="7.99983"
                      rx="1.99983"
                      ry="1.99983"
                      fill="#20419B"
                    />
                    <path
                      d="M10 2.66602C10 3.77058 9.10457 4.66602 8 4.66602C6.89543 4.66602 6 3.77058 6 2.66602C6 1.56145 6.89543 0.666016 8 0.666016C9.10457 0.666016 10 1.56145 10 2.66602Z"
                      fill="#20419B"
                    />
                    <path
                      d="M10 13.334C10 14.4386 9.10457 15.334 8 15.334C6.89543 15.334 6 14.4386 6 13.334C6 12.2294 6.89543 11.334 8 11.334C9.10457 11.334 10 12.2294 10 13.334Z"
                      fill="#20419B"
                    />
                    <ellipse
                      cx="13.3328"
                      cy="7.99983"
                      rx="1.99983"
                      ry="1.99983"
                      fill="#20419B"
                    />
                  </svg>
                </div>
                <p class="robo-12-400 mb-1">{{ item.chuc_vu }}</p>
              </template>
            </v-select>
          </div>
          <div class="mb-3">
            <p class="robo-18-400 txt-grey-600">Lời mời tham gia</p>
            <textarea
              v-model="message"
              class="form-control border border-2 bg-white"
              rows="5"
              placeholder="Nhập nội dung ..."
            ></textarea>
          </div>
          <div
            class="mb-3 d-flex align-items-center"
            v-show="room && room.repeat_type"
          >
            <input
              id="type1"
              type="radio"
              v-model="type"
              :value="1"
              class="mr-2"
            />
            <label for="type1" class="robo-16-400 mb-0 mr-2"
              >Tham gia một lần</label
            >
            <input
              v-show="
                type === 1 && (room.repeat_type == 1 || room.repeat_type == 2)
              "
              id="datePickMember"
              v-model="date"
              class="radius-10 border input-date"
            />
          </div>
          <div class="mb-3 d-flex align-items-center" v-if="room.repeat_type">
            <input
              id="type2"
              type="radio"
              v-model="type"
              :value="2"
              class="mr-2"
            />
            <label for="type2" class="robo-16-400 mb-0 mr-2"
              >Tham gia định kỳ</label
            >
          </div>
        </div>
        <div class="d-flex justify-content-center mb-3">
          <button
            type="button"
            class="btn bg-pri bd-pri text-white w-25"
            @click="submit"
          >
            Lưu
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import appUtils from '../../utils/appUtils'
import moment from 'moment'
export default {
  name: 'OrganizationModalAddMember',
  props: ['room', 'showing_date'],
  components: { vSelect },
  data () {
    return {
      doctors: [],
      selected_doctor: null,
      message: '',
      type: 1,
      date: moment().format('YYYY-MM-DD'),
      search_dtr_string: ''
    }
  },
  watch: {
    room (room) {
      this.changeRoom(room)
    }
  },
  computed: {

    filteredAndSorted () {
      // function to compare names
      function compare (a, b) {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      }
      let search_string = appUtils.removeVietnameseTones(this.search_dtr_string.toLowerCase())
      const result = this.doctors?.filter(dr => {
        return appUtils.removeVietnameseTones(dr.name.toLowerCase()).includes(search_string) || (dr?.user?.email && appUtils.removeVietnameseTones(dr?.user?.email.toLowerCase()).includes(search_string))
      }).sort(compare)
      return result
    }
  },
  mounted () {
    let self = this
    self.getConectiveDoctors()
    window.$(document).ready(function () {
      window.$('#modalAddMember').on('shown.bs.modal', function (e) {
        self.changeRoom(self.room)
      })
    })
  },
  methods: {
    async getConectiveDoctors () {
      const self = this
      if (!this.$clinicId) return
      let params = {
        clinic_id: this.$clinicId,
        circle: 1
      }
      await this.$rf.getRequest('DoctorRequest').getConectiveDoctors(params).then((r) => {
        self.doctors = r.data
        let dw = r.data?.workspace
        let dc = r.data?.circle?.map(dc => { return Object.assign({ circle: 1 }, dc) })
        if (dw && dc) {
          self.doctors = dw.concat(dc)
        } else if (dw) {
          self.doctors = dw
        } else if (dc) {
          self.doctors = dc
        }
      })
    },
    searchDoctor (search) {
      this.search_dtr_string = search
    },
    async submit () {
      let self = this
      if (!self.room) return
      let mdt_room_id = self.room?.id
      let member = {
        doctor_id: self.selected_doctor.id,
        role: 2,
        message: self.message
      }
      if (self.type === 1) {
        member.join_date = self.date ? moment(self.date, 'DD-MM-YYYY').format('DD-MM-YYYY') : window.moment().format('DD-MM-YYYY')
      }
      if (!self.room.repeat_type) {
        member.join_date = moment(self.room.date).format('DD-MM-YYYY')
      }
      let params = {
        members: []
      }
      params.members.push(member)
      self.$rf.getRequest('DoctorRequest').addMdtMembers(mdt_room_id, params).then(() => {
        self.$emit('refresh')
      })
    },
    changeRoom (room) {
      if (!room) return
      let self = this
      let day = null
      if (room.repeat_type) {
        if (room.repeat_type === 2) {
          day = moment(room.date).day()
        }
        self.date = self.showing_date.format('DD-MM-YYYY')
        window.flatpickr(document.getElementById('datePickMember'), {
          defaultDate: self.date,
          minDate: new Date(),
          dateFormat: 'd-m-Y',
          disable: [function (date) {
            var dayOfThis = date.getDay()
            return day ? dayOfThis !== day : false
          }],
          onChange: function (date) {
            if (!date[0]) return
            let newDate = moment(date[0])
            self.date = newDate.format('DD-MM-YYYY')
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.input-date {
  height: 40px;
  font-size: 16px;
  color: black;
  padding-left: 5px;
}
</style>