<template>
  <div class="container">
    <div>
      <div class="d-flex align-items-center justify-content-between">
        <p class="robo-24-500 txt-black mb-3">Tài liệu cuộc họp</p>

        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="cursor-pointer"
          @click="onClose"
        >
          <path
            d="M6 18.4998L17.9998 6.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.9998 18.4998L6 6.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <!-- item -->
    <div class="row">
      <div
        class="col-xl-3 col-lg-4 col-sm-6 mb-3"
        @click="modalUpload(true)"
      >
        <div class="item-doc">
          <div class="item-doc-thumb ratio ratio-16x9">
            <div class="d-flex h-100 justify-content-center align-items-center">
              <img
                src="../../../public/assets/images/MDT/upload-doc.svg"
                width="64"
                height="64"
                alt=""
              />
            </div>
          </div>
          <div class="item-doc-info px-3">
            <button class="btn bg-pri bd-pri text-white robo-10-400 mb-0 w-100">
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="5.42773"
                  y="5.95605"
                  width="6.80812"
                  height="7.56458"
                  fill="#20419B"
                />
                <path
                  d="M9.21008 2.17383C5.03444 2.17383 1.64551 5.56276 1.64551 9.7384C1.64551 13.914 5.03444 17.303 9.21008 17.303C13.3857 17.303 16.7747 13.914 16.7747 9.7384C16.7747 5.56276 13.3857 2.17383 9.21008 2.17383ZM11.8804 9.7611C11.7669 9.87457 11.6232 9.92752 11.4795 9.92752C11.3357 9.92752 11.192 9.87457 11.0785 9.7611L9.77743 8.45999V12.386C9.77743 12.6962 9.52023 12.9533 9.21008 12.9533C8.89994 12.9533 8.64274 12.6962 8.64274 12.386V8.45999L7.34163 9.7611C7.12226 9.98047 6.75916 9.98047 6.53979 9.7611C6.32042 9.54172 6.32042 9.17863 6.53979 8.95925L8.80916 6.68988C9.02853 6.47051 9.39163 6.47051 9.61101 6.68988L11.8804 8.95925C12.0998 9.17863 12.0998 9.54172 11.8804 9.7611Z"
                  fill="white"
                />
              </svg>
              Tải lên
            </button>
          </div>
        </div>
      </div>

      <div
        class="col-xl-3 col-lg-4 col-sm-6 mb-3 position-relative"
        v-for="(doc, i) in docAttachments"
        :key="doc.id + '-' + i"
      >
        <div
          class="item-doc"
          :set="[(extInfo = getExtInfo(doc))]"
          @click="openDocAttach(doc)"
        >
          <div
            v-if="extInfo.is_image"
            class="item-doc-thumb ratio ratio-16x9 radius-top-10"
            :style="{ 'background-image': 'url(' + doc.url + ')' }"
          ></div>
          <div
            v-if="!extInfo.is_image"
            class="item-doc-thumb ratio ratio-16x9 radius-10"
          >
            <div class="d-flex h-100 justify-content-center align-items-center">
              <p class="mb-0 robo-34-700 txt-pri text-uppercase">
                {{ extInfo.ext }}
              </p>
            </div>
          </div>
          <div class="item-doc-info p-3">
            <p class="txt-black robo-14-400 mb-1 max-line-1">{{ doc.name }}</p>
            <p class="txt-black robo-12-400 mb-0 max-line-1">
              Tải lên: {{ formatDMY(doc.created_at) }}
            </p>
            <p class="txt-black robo-12-400 mb-0 max-line-1" v-if="doc.date">
              Ngày họp: {{ formatDMY(doc.date) }}
            </p>
          </div>
        </div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="delete-btn"
          @click="deleteMeetRoomAttach(doc.id)"
        >
          <path
            d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
            fill="#FA0303"
          />
        </svg>
      </div>
    </div>
    <!-- item -->
    <div class="w-100 text-center my-3" v-if="meet_room">
      <a
        href="javascript:;"
        class="txt-pri robo-16-400"
        v-if="attachments.current_page < attachments.last_page"
        @click="getMeetRoomAttachs(meet_room.id, true)"
      >
        Xem thêm
      </a>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="modalUpload"
      tabindex="-1"
      aria-labelledby="modalUploadTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content" :set="(count = selected_docs.length)">
          <div class="modal-header border-0 bg-white">
            <h5 class="modal-title robo-12-500 txt-black" id="modalUploadTitle">
              Chọn tệp
            </h5>
            <!-- <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> -->
          </div>
          <div class="modal-body">
            <label class="robo-12-400 txt-black" v-if="meet_room && meet_room.repeat_type"
              >Ngày họp <span class="text-danger">*</span></label
            >
            <input
              v-if="meet_room && meet_room.repeat_type"
              type="date"
              class="form-control txt-pri mb-0"
              v-model="date"
              placeholder="Nhập tên tài liệu ...."
              @input="err_date = ''"
            />
            <label class="robo-12-400 txt-black"
              >Tên tài liệu <span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control txt-pri mb-0"
              v-model="name"
              placeholder="Nhập tên tài liệu ...."
              @input="err_name = ''"
            />
            <p class="mb-3 robo-12-400 text-danger">{{ err_name }}</p>
            <p class="mb-2 robo-14-400 txt-pri">
              <span v-if="count">Đã chọn {{ count }} files</span>
            </p>
            <div class="upload-btn" @click="openInputDoc">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.7369 2.76175H8.08489C6.00489 2.75375 4.30089 4.41075 4.25089 6.49075V17.2277C4.20589 19.3297 5.87389 21.0697 7.97489 21.1147C8.01189 21.1147 8.04889 21.1157 8.08489 21.1147H16.0729C18.1629 21.0407 19.8149 19.3187 19.8029 17.2277V8.03775L14.7369 2.76175Z"
                  stroke="#20419B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.4756 2.75V5.659C14.4756 7.079 15.6246 8.23 17.0446 8.234H19.7986"
                  stroke="#20419B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.6416 9.90918V15.9502"
                  stroke="#20419B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.9869 12.2642L11.6419 9.90918L9.29688 12.2642"
                  stroke="#20419B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span class="robo-14-500 txt-pri"
                >Chọn hình ảnh, PDF, Doc, Docx, Xlsx, Ppt</span
              >
            </div>
            <p class="mb-3 robo-12-400 text-danger">{{ err_file }}</p>
          </div>
          <div class="modal-footer border-0 justify-content-center">
            <button
              type="button"
              class="btn bg-pri bd-pri text-white radius-10 w-100"
              @click="onFinishUpload()"
              :disabled="!name || !count"
            >
              Tải lên
            </button>
          </div>
        </div>
      </div>
    </div>
    <input
      type="file"
      ref="inputFile"
      accept=".jpeg, .png, .jpg, .gif, .svg, .doc, .docx, .pdf, .xls, .xlsx, .xlsm, .ppt, .pptx"
      @change="onSelectDoc($event)"
      multiple
      class="d-none"
    />
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'MeetRoomAttachments',
  data () {
    return {
      exts: ['jpeg', 'png', 'jpg', 'gif', 'svg', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'xlsm', 'ppt', 'pptx'],
      selected_docs: [],
      name: '',
      err_name: '',
      date: '',
      err_date: '',
      err_file: '',
      img_exts: ['jpeg', 'png', 'jpg', 'gif', 'svg'],
      attachments: [],
      user_id: null,
      meet_room: null,
      showing_date: window.moment()
    }
  },
  computed: {
    docAttachments () {
      return this.attachments?.data?.filter(f => f.type === 1 || f.type === 2)
    }
  },
  mounted () {
    this.showMeetRoom()
    this.user_id = appUtils.getLocalUser()?.id
  },
  methods: {
    async showMeetRoom () {
      let self = this
      await self.$rf.getRequest('DoctorRequest').showMeetRoom(this.$route.params.id).then((r) => {
        self.meet_room = r.data
        self.getMeetRoomAttachs(r.data.id)
      })
    },
    async getMeetRoomAttachs (id, is_next) {
      let self = this
      let page = 1
      if (!is_next) {
        self.attachments = []
      } else {
        page = this.params_disease.page + 1
      }
      let params = {
        // date: self.showing_date.format('DD-MM-YYYY'),
        page: page,
        limit: 100,
        sort: 'date',
        sort_by: 'desc'
      }
      self.$rf.getRequest('DoctorRequest').getMeetRoomAttachs(id, params).then((r) => {
        if (r && r.data) {
          self.attachments.current_page = r.data.current_page
          if (!is_next) {
            self.attachments = r.data
          } else {
            if (r.data?.count) {
              let old = self.attachments.data
              let newObj = r.data.data
              self.attachments.data = old.concat(newObj)
            }
          }
        }
      })
    },
    async deleteMeetRoomAttach (id) {
      let self = this
      let r = confirm('Bạn có chắc chắn muốn xóa tài liệu này?')
      if (!r) return
      await this.$rf.getRequest('DoctorRequest').deleteMeetRoomAttach(id).then(r => {
        self.showMeetRoom()
      })
    },
    onClose () {
      this.$router.push({ name: 'ListMdt', query: { meetid: this.$route.params.id } })
    },
    openInputDoc () {
      this.$refs.inputFile.click()
    },
    onSelectDoc (e) {
      const self = this
      const files = e.target.files
      self.err_file = ''
      console.log(files)
      let errFile = ''
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const f = files[i]
          if (this.validateFile(f.name.split('.').pop())) {
            self.selected_docs.push(f)
          } else {
            errFile += (i + 1) + ','
          }
        }
      }
      self.handleClearInput()
      if (errFile) alert('File số ' + errFile.substring(0, errFile.length - 1) + ' không đúng định dạng nên đã bị loại bỏ')
    },
    validateFile (ext) {
      return this.exts.findIndex(e => e === ext.toLowerCase()) > -1
    },
    isImage (ext) {
      return this.img_exts.findIndex(e => e === ext.toLowerCase()) > -1
    },
    onFinishUpload () {
      if (!this.name) {
        this.err_name = 'Vui lòng nhập tên tài liệu'
        return
      }
      if (!this.date && this.meet_room.repeat_type) {
        this.err_date = 'Vui lòng nhập ngày họp'
        return
      }
      if (!this.selected_docs || !this.selected_docs.length) {
        this.err_file = 'Vui lòng chọn tài liệu'
        return
      }
      let date = null
      if (this.date) {
        date = window.moment(this.date).format('DD-MM-YYYY')
      }
      this.onSubmitFiles(this.selected_docs, this.name, date)
    },
    onDeleteDoc (doc, i) {
      const r = confirm('Bạn có chắc chắn muốn xóa file này')
      if (!r) return
      this.$emit('onDeleteDoc', doc, i)
      // window.event.stopPropagation()
    },
    getBase64URL (file) {
      return URL.createObjectURL(file)
    },
    getImageUrl (path) {
      return appUtils.getDocumentURL(path)
    },
    modalUpload (show) {
      window.$('#modalUpload').modal(show ? 'show' : 'hide')
    },
    getLocalExtInfo (doc) {
      const result = {
        is_image: false,
        ext: ''
      }
      if (this.isImage(doc.name.split('.').pop())) {
        result.is_image = true
      } else {
        result.ext = doc.name.split('.').pop()
      }
      return result
    },
    getExtInfo (doc) {
      const result = {
        is_image: false,
        ext: ''
      }
      if (this.isImage(doc.url.split('.').pop())) {
        result.is_image = true
      } else {
        result.ext = doc.url.split('.').pop()
      }
      return result
    },
    handleClearInput () {
      this.$refs.inputFile.value = null
    },
    async onSubmitFiles (docs, name, date) {
      let self = this
      let params = {
        meet_room_id: this.$route.params.id,
        date: date
      }
      const formData = new FormData()
      for (var key in params) {
        if (params[key]) formData.append(key, params[key])
      }
      docs.forEach((file, i) => {
        formData.append('data[' + i + '][name]', name || null)
        formData.append('data[' + i + '][file]', file)
        formData.append('data[' + i + '][type]', 1)
      })
      await self.$rf.getRequest('DoctorRequest').multiAddMeetRoomAttachs(formData).then((r) => {
        self.showMeetRoom()
        self.modalUpload(false)
        self.name = ''
        self.selected_docs = []
        return r
      })
    },
    openDocAttach (doc) {
      window.open(doc.url)
    },
    formatDMY (dt) {
      return window.moment(dt).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped>
.item-doc {
  background: #eff2ff;
  border-radius: 10.5904px;
  cursor: pointer;
}
.item-doc-info {
  height: 76px;
  overflow: hidden;
}
.upload-btn {
  width: 100%;
  border: 0.756458px dashed #20409b;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
}
.form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  color: #20419B;
  padding-left: 0;
  
}
.radius-top-10 {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.delete-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
</style>
