<template>
  <OrgListEmployee :orgId="orgId" :title="$t('lbl_list_of_staff')" />
</template>

  <script>
import appUtils from '../../utils/appUtils'
import OrgListEmployee from '@/components/OrgListEmployee.vue'

export default {
  name: 'OrgEmployeeManage',
  components: {
    OrgListEmployee
  },
  data () {
    return {
      org: '',
      appUtils,
      searchValue: null,
      stat: 1,
      orgId: null
    }
  },
  beforeMount () {
    this.orgId = this.$globalOrg?.id
  },
  computed: {
    doctorRequest () {
      return this.$rf.getRequest('DoctorRequest')
    }
  },
  mounted () {
    this.getOrg(this.orgId)
  },
  methods: {
    async getOrg (id) {
      if (!id) return
      const res = await this.$rf.getRequest('DoctorRequest').getOrg(id)
      this.org = res.data
    }
  }
}
</script>

  <style scoped>
::placeholder {
  color: #98a2b3;
}
.border-bottom-2 {
  border-bottom: 2px solid #20419b !important;
}
.custom:hover {
  background-color: #e4e7ec;
}
</style>
