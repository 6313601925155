<template>
  <div>
    <div class="row m-0 overflow-hidden">
      <div class="col-lg-4 d-none d-lg-block bg-img-login"></div>
      <div class="col-lg-8 p-0 y100-auto">
        <!-- Login -->
        <form v-if="!login_otp" @submit.prevent="onLogin" class="h-100 d-flex flex-column">
          <div class="container">
            <div class="flex justify-between pt-2">
              <div class="pt-4">
                <div class="d-flex fs-27 fw-700">
                  <svg class="mr-1" width="32" height="33" viewBox="0 0 32 33" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.91091 30.1988L2.52089 22.8088C-0.840297 19.4476 -0.840297 13.9951 2.52089 10.6306L9.91091 3.24062C13.2721 -0.12057 18.7246 -0.12057 22.0891 3.24062L29.4791 10.6306C32.8403 13.9918 32.8403 19.4444 29.4791 22.8088L22.0891 30.1988C18.7246 33.56 13.2754 33.56 9.91091 30.1988Z"
                      fill="#20409B"/>
                    <path
                      d="M16.1889 10.7322C16.4802 10.706 16.7584 10.6537 17.0136 10.5719C17.4686 10.4475 17.8678 10.2249 18.1951 9.93038C18.2409 9.89111 18.2868 9.84529 18.3326 9.80274L18.349 9.78638C18.9512 9.18418 19.2817 8.33325 19.2326 7.3514C19.1966 6.61174 18.9282 5.95391 18.4995 5.44335C18.3817 5.29935 18.2508 5.16843 18.1068 5.05061C17.5929 4.6186 16.9351 4.3535 16.1954 4.31422C15.2594 4.2684 14.451 4.56296 13.8554 5.10952C13.8226 5.13897 13.7932 5.16516 13.7637 5.19461C13.3874 5.5579 13.1092 6.02264 12.9652 6.56593C12.9619 6.58229 12.9553 6.59865 12.9521 6.61502C12.8866 6.84739 12.8408 7.09612 12.8179 7.35468C12.8179 7.3874 12.8113 7.42013 12.8081 7.44958C12.4579 11.1806 10.5433 13.1247 6.84499 13.5076C6.7959 13.5141 6.74681 13.5174 6.69772 13.5239C4.81912 13.6941 3.5918 14.9672 3.60162 16.7378C3.60817 17.6542 3.95508 18.4397 4.55074 19.0059C5.11694 19.6016 5.90242 19.9452 6.81553 19.9518C8.58613 19.9648 9.85926 18.7375 10.0294 16.8557C10.0327 16.8033 10.0393 16.7575 10.0458 16.7084C10.4255 13.0068 12.3728 11.0922 16.1038 10.742C16.1267 10.7388 16.1594 10.7355 16.1889 10.7322Z"
                      fill="white"
                    />
                    <path
                      d="M25.3608 19.906C25.6521 19.8799 25.9302 19.8275 26.1855 19.7457C26.6404 19.6213 27.0397 19.3988 27.367 19.1042C27.4128 19.0649 27.4586 19.0191 27.5045 18.9766L27.5208 18.9602C28.123 18.358 28.4536 17.5071 28.4045 16.5252C28.3685 15.7856 28.1001 15.1277 27.6714 14.6172C27.5536 14.4732 27.4226 14.3423 27.2786 14.2244C26.7648 13.7924 26.107 13.5273 25.3673 13.4881C24.4313 13.4422 23.6229 13.7368 23.0273 14.2833C22.9945 14.3128 22.9651 14.339 22.9356 14.3684C22.5592 14.7317 22.281 15.1965 22.137 15.7398C22.1338 15.7561 22.1272 15.7725 22.1239 15.7888C22.0585 16.0212 22.0127 16.2699 21.9898 16.5285C21.9898 16.5612 21.9832 16.594 21.9799 16.6234C21.6298 20.3544 19.7152 22.2985 16.0169 22.6814C15.9678 22.6879 15.9187 22.6912 15.8696 22.6978C13.991 22.868 12.7637 24.1411 12.7735 25.9117C12.78 26.8281 13.127 27.6135 13.7226 28.1797C14.2888 28.7754 15.0743 29.119 15.9874 29.1256C17.758 29.1387 19.0311 27.9114 19.2013 26.0295C19.2046 25.9771 19.2111 25.9313 19.2177 25.8822C19.5973 22.1807 21.5447 20.2661 25.2757 19.9159C25.2986 19.9126 25.328 19.9093 25.3608 19.906Z"
                      fill="white"
                    />
                    <path
                      d="M16.0116 19.9489C17.801 19.9489 19.2517 18.4983 19.2517 16.7088C19.2517 14.9194 17.801 13.4688 16.0116 13.4688C14.2221 13.4688 12.7715 14.9194 12.7715 16.7088C12.7715 18.4983 14.2221 19.9489 16.0116 19.9489Z"
                      fill="white"/>
                  </svg>

                  <p class="txt-pri mb-0">HODO</p>
                </div>
                <div class="fs-14 fw-500 txt-black">
                  <p>{{ $t("common.lbl_slogan") }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="container flex flex-col h-full">
            <div class>
              <div class="flex flex-1 flex-col h-full">
                <div class="pt-md-3 w-100-441px">
                  <div>
                    <div class="txt-black fs-32 fw-600">
                      <p>{{ $t("login.welcome") }}</p>
                    </div>
                    <div class="flex justify-between items-center mb-3">
                      <div class="flex items-center">
                        <div>
                          <svg class="mr-1" width="24" height="24" viewBox="0 0 24 24" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.41 8.64C21.41 8.64 21.41 8.64 21.41 8.59C20.7054 6.66623 19.4269 5.00529 17.7475 3.83187C16.0681 2.65845 14.0688 2.02917 12.02 2.02917C9.97126 2.02917 7.97195 2.65845 6.29252 3.83187C4.6131 5.00529 3.33461 6.66623 2.63001 8.59C2.63001 8.59 2.63001 8.59 2.63001 8.64C1.84314 10.8109 1.84314 13.1891 2.63001 15.36C2.63001 15.36 2.63001 15.36 2.63001 15.41C3.33461 17.3338 4.6131 18.9947 6.29252 20.1681C7.97195 21.3416 9.97126 21.9708 12.02 21.9708C14.0688 21.9708 16.0681 21.3416 17.7475 20.1681C19.4269 18.9947 20.7054 17.3338 21.41 15.41C21.41 15.41 21.41 15.41 21.41 15.36C22.1969 13.1891 22.1969 10.8109 21.41 8.64ZM4.26001 14C3.91324 12.6892 3.91324 11.3108 4.26001 10H6.12001C5.96004 11.3285 5.96004 12.6715 6.12001 14H4.26001ZM5.08001 16H6.48001C6.71473 16.8918 7.05022 17.7541 7.48001 18.57C6.49931 17.9019 5.67951 17.0241 5.08001 16ZM6.48001 8H5.08001C5.67088 6.97909 6.4802 6.10147 7.45001 5.43C7.03057 6.24725 6.70515 7.10942 6.48001 8ZM11 19.7C9.77178 18.7987 8.90916 17.4852 8.57001 16H11V19.7ZM11 14H8.14001C7.9534 12.6732 7.9534 11.3268 8.14001 10H11V14ZM11 8H8.57001C8.90916 6.51477 9.77178 5.20132 11 4.3V8ZM18.92 8H17.52C17.2853 7.10816 16.9498 6.24594 16.52 5.43C17.5007 6.09807 18.3205 6.97594 18.92 8ZM13 4.3C14.2282 5.20132 15.0909 6.51477 15.43 8H13V4.3ZM13 19.7V16H15.43C15.0909 17.4852 14.2282 18.7987 13 19.7ZM15.86 14H13V10H15.86C16.0466 11.3268 16.0466 12.6732 15.86 14ZM16.55 18.57C16.9798 17.7541 17.3153 16.8918 17.55 16H18.95C18.3505 17.0241 17.5307 17.9019 16.55 18.57ZM19.74 14H17.88C17.9613 13.3365 18.0014 12.6685 18 12C18.0011 11.3315 17.961 10.6636 17.88 10H19.74C20.0868 11.3108 20.0868 12.6892 19.74 14Z"
                              fill="#41464B"
                            />
                          </svg>
                          <span class="fs-16 fw-600 mr-3 text-black">
                            {{ $t("common.choose_lang") }}
                          </span>
                        </div>
                        <language/>
                      </div>
                    </div>
                    <div class>
                      <div class="mb-3">
                        <input v-model="form.username" @keyup.enter="onLogin"
                               :placeholder="$t('login.input_text.email')" type="text"
                               class="form-control form-input fs-16 fw-500" name="email"/>
                        <p class="my-1 pl-3 fs-10 text-danger">{{ error.username || " " }}</p>
                      </div>
                      <div class="mb-3">
                        <div class="position-relative">
                          <input v-model="form.password" @keyup.enter="onLogin"
                                 :placeholder="$t('login.input_text.password')" :type="show_pw ? 'password' : 'text'"
                                 class="form-control form-input fs-16 fw-500" name="password"/>
                          <span @click="show_pw = !show_pw" class="position-absolute border-0 cursor-pointer mr-2"
                                style="top: 14px; right: 10px">
                            <i
                              :class="!show_pw ? 'font-size-20 text-muted fa fa-eye' : 'font-size-20 text-muted fa fa-eye-slash'"></i>
                          </span>
                        </div>

                        <p class="my-1 pl-3 fs-10 text-danger">{{ error.password || " " }}</p>
                        <div class="text-right">
                          <a class="txt-pri w-100 fs-14 fw-500" href="javascript:;" @click="onShowModalForgot(true)">
                            {{ $t("login.messages.forgot_password") }}
                          </a>
                        </div>
                      </div>
                      <div class="form-footer mb-3">
                        <button type="button" @click="onLogin()"
                                class="btn bg-pri form-btn w-100 fs-16 fw-600 mb-3 text-white"
                                :disabled="!form.username || !form.password">{{ $t("login.buttons.login") }}
                        </button>
                        <p
                          class="fs-14 fw-400 txt-grey-700 text-center relative after:absolute after:w-full after:top-1/2 after:border-t after:border-t-gray-100">
                          - {{ $t("login.other_labels.lbl_or") }} -</p>
                        <button type="button" @click="login_otp = true"
                                class="btn bg-white bd-pri txt-pri form-btn w-100 fs-16 fw-600 mb-3">
                          {{ $t("login.buttons.login_with_otp") }}
                        </button>
                        <div class="flex justify-end mb-3">
                          <p class="text-left txt-black fs-14 fw-500 mb-0">
                            {{ $t("login.messages.none_account") }}
                            <a class="txt-pri" href="javascript:;" @click="onSignUp()">
                              {{ $t("login.messages.register") }}
                            </a>
                          </p>
                        </div>
                        <div
                          v-if="currentDomain === 'localhost' || currentDomain === 'dev.hodo.app' || currentDomain === 'hodo.app'">
                          <p
                            class="fs-14 fw-400 txt-grey-700 text-center relative after:absolute after:w-full after:top-1/2 after:border-t after:border-t-gray-100">
                            - {{ $t("login.other_labels.lbl_or") }} -</p>
                          <div>
                            <button @click="onCreateNewOrg" type="button"
                                    class="btn bg-pri text-white form-btn w-100 fs-16 fw-600">
                              {{ $t("sign_up.lbl_register_for_a_business_account") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-100-441px mt-auto">
            <p class="fs-13 fw-500"
               v-html="$t('login.other_labels.lbl_term_and_policy', [`<a class='txt-pri' href='#/dieu-khoan' target='_blank'>${$t('login.other_labels.lbl_term_of_service')}</a>`, `<a class='txt-pri' href='#/chinh-sach' target='_blank'>${$t('login.other_labels.lbl_privacy_policy')}</a>`])"/>
          </div>
        </form>

        <!-- Verification OTP -->
        <form @submit.prevent="() => {}" class="h-100 d-flex flex-column justify-content-between"
              v-if="!loading && login_otp && sendedOtp">
          <div class="pt-lg-2 mt-4">
            <div class="d-flex fs-27 fw-700 pl-3">
              <svg class="mr-1" width="32" height="33" viewBox="0 0 32 33" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.91091 30.1988L2.52089 22.8088C-0.840297 19.4476 -0.840297 13.9951 2.52089 10.6306L9.91091 3.24062C13.2721 -0.12057 18.7246 -0.12057 22.0891 3.24062L29.4791 10.6306C32.8403 13.9918 32.8403 19.4444 29.4791 22.8088L22.0891 30.1988C18.7246 33.56 13.2754 33.56 9.91091 30.1988Z"
                  fill="#20409B"/>
                <path
                  d="M16.1889 10.7322C16.4802 10.706 16.7584 10.6537 17.0136 10.5719C17.4686 10.4475 17.8678 10.2249 18.1951 9.93038C18.2409 9.89111 18.2868 9.84529 18.3326 9.80274L18.349 9.78638C18.9512 9.18418 19.2817 8.33325 19.2326 7.3514C19.1966 6.61174 18.9282 5.95391 18.4995 5.44335C18.3817 5.29935 18.2508 5.16843 18.1068 5.05061C17.5929 4.6186 16.9351 4.3535 16.1954 4.31422C15.2594 4.2684 14.451 4.56296 13.8554 5.10952C13.8226 5.13897 13.7932 5.16516 13.7637 5.19461C13.3874 5.5579 13.1092 6.02264 12.9652 6.56593C12.9619 6.58229 12.9553 6.59865 12.9521 6.61502C12.8866 6.84739 12.8408 7.09612 12.8179 7.35468C12.8179 7.3874 12.8113 7.42013 12.8081 7.44958C12.4579 11.1806 10.5433 13.1247 6.84499 13.5076C6.7959 13.5141 6.74681 13.5174 6.69772 13.5239C4.81912 13.6941 3.5918 14.9672 3.60162 16.7378C3.60817 17.6542 3.95508 18.4397 4.55074 19.0059C5.11694 19.6016 5.90242 19.9452 6.81553 19.9518C8.58613 19.9648 9.85926 18.7375 10.0294 16.8557C10.0327 16.8033 10.0393 16.7575 10.0458 16.7084C10.4255 13.0068 12.3728 11.0922 16.1038 10.742C16.1267 10.7388 16.1594 10.7355 16.1889 10.7322Z"
                  fill="white"
                />
                <path
                  d="M25.3608 19.906C25.6521 19.8799 25.9302 19.8275 26.1855 19.7457C26.6404 19.6213 27.0397 19.3988 27.367 19.1042C27.4128 19.0649 27.4586 19.0191 27.5045 18.9766L27.5208 18.9602C28.123 18.358 28.4536 17.5071 28.4045 16.5252C28.3685 15.7856 28.1001 15.1277 27.6714 14.6172C27.5536 14.4732 27.4226 14.3423 27.2786 14.2244C26.7648 13.7924 26.107 13.5273 25.3673 13.4881C24.4313 13.4422 23.6229 13.7368 23.0273 14.2833C22.9945 14.3128 22.9651 14.339 22.9356 14.3684C22.5592 14.7317 22.281 15.1965 22.137 15.7398C22.1338 15.7561 22.1272 15.7725 22.1239 15.7888C22.0585 16.0212 22.0127 16.2699 21.9898 16.5285C21.9898 16.5612 21.9832 16.594 21.9799 16.6234C21.6298 20.3544 19.7152 22.2985 16.0169 22.6814C15.9678 22.6879 15.9187 22.6912 15.8696 22.6978C13.991 22.868 12.7637 24.1411 12.7735 25.9117C12.78 26.8281 13.127 27.6135 13.7226 28.1797C14.2888 28.7754 15.0743 29.119 15.9874 29.1256C17.758 29.1387 19.0311 27.9114 19.2013 26.0295C19.2046 25.9771 19.2111 25.9313 19.2177 25.8822C19.5973 22.1807 21.5447 20.2661 25.2757 19.9159C25.2986 19.9126 25.328 19.9093 25.3608 19.906Z"
                  fill="white"
                />
                <path
                  d="M16.0116 19.9489C17.801 19.9489 19.2517 18.4983 19.2517 16.7088C19.2517 14.9194 17.801 13.4688 16.0116 13.4688C14.2221 13.4688 12.7715 14.9194 12.7715 16.7088C12.7715 18.4983 14.2221 19.9489 16.0116 19.9489Z"
                  fill="white"/>
              </svg>
              <p class="txt-pri mb-0">HODO</p>
            </div>
            <div class="fs-14 fw-500 txt-black pl-3">
              <p>{{ $t("common.lbl_slogan") }}</p>
            </div>
            <div class="pt-md-3 w-100-441px">
              <div>
                <p class="fs-32 fw-600 txt-black mb-12 mt-4">{{ $t("login.lbl_enter_verification_otp_code") }}</p>
                <p class="fs-14 fw-500 mb-1">{{ $t("login.messages.a_otp_code_has_sent_to_your_email") }}:</p>
                <p class="fs-16 fw-600">
                  {{ form_otp.email }}
                  <a
                    class="fs-14 fw-500 txt-pri"
                    href="javascript:;"
                    @click="
                      () => {
                        sendedOtp = false
                      }
                    "
                  >{{ $t("login.messages.change_email") }}</a
                  >
                </p>
              </div>
              <div class="text-start">
                <div class="mb-3">
                  <input type="text" class="form-control form-input fs-16 fw-500"
                         :placeholder="$t('login.input_text.enter_otp')" required @keyup.enter="onLoginOTP"
                         v-model="form_otp.otp"/>
                  <p class="fs-14 fw-500 text-right txt-pri mt-4 cursor-pointer" @click="onSendOtp" v-if="!time_out">
                    {{ $t("login.messages.send_otp_again") }}
                  </p>
                  <p v-if="time_out" class="fs-14 fw-500 text-right mt-4">
                    <span class>{{ $t("login.messages.send_otp_again") }}</span>
                    <span class="txt-pri"> 00:{{ time_out && (time_out > 10 ? time_out : "0" + time_out) }} </span>
                  </p>
                </div>
                <div class="mt-4">
                  <div class>
                    <button type="button" class="mr-2 btn bg-pri form-btn w-100 fs-16 fw-600 text-white mb-24"
                            :disabled="loading || !form_otp.otp" @click="onLoginOTP">
                      <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
                      {{ $t("login.buttons.login") }}
                    </button>
                    <a
                      class="fs-14 fw-500 txt-pri"
                      href="javascript:;"
                      @click="
                        () => {
                          login_otp = false
                          sendedOtp = false
                        }
                      "
                    >{{ $t("login.other_labels.lbl_login_with_password") }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100-441px">
            <p class="fs-13 fw-500"
               v-html="$t('login.other_labels.lbl_term_and_policy', [`<a class='txt-pri' href='#/dieu-khoan' target='_blank'>${$t('login.other_labels.lbl_term_of_service')}</a>`, `<a class='txt-pri' href='#/chinh-sach' target='_blank'>${$t('login.other_labels.lbl_privacy_policy')}</a>`])"/>
          </div>
        </form>

        <!-- Login OTP -->
        <form @submit.prevent="() => {}" class="h-100vh d-flex flex-column justify-content-between"
              v-if="!loading && login_otp && !sendedOtp">
          <div class="pt-lg-2 mt-4">
            <div class="d-flex fs-27 fw-700 pl-3">
              <svg class="mr-1" width="32" height="33" viewBox="0 0 32 33" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.91091 30.1988L2.52089 22.8088C-0.840297 19.4476 -0.840297 13.9951 2.52089 10.6306L9.91091 3.24062C13.2721 -0.12057 18.7246 -0.12057 22.0891 3.24062L29.4791 10.6306C32.8403 13.9918 32.8403 19.4444 29.4791 22.8088L22.0891 30.1988C18.7246 33.56 13.2754 33.56 9.91091 30.1988Z"
                  fill="#20409B"/>
                <path
                  d="M16.1889 10.7322C16.4802 10.706 16.7584 10.6537 17.0136 10.5719C17.4686 10.4475 17.8678 10.2249 18.1951 9.93038C18.2409 9.89111 18.2868 9.84529 18.3326 9.80274L18.349 9.78638C18.9512 9.18418 19.2817 8.33325 19.2326 7.3514C19.1966 6.61174 18.9282 5.95391 18.4995 5.44335C18.3817 5.29935 18.2508 5.16843 18.1068 5.05061C17.5929 4.6186 16.9351 4.3535 16.1954 4.31422C15.2594 4.2684 14.451 4.56296 13.8554 5.10952C13.8226 5.13897 13.7932 5.16516 13.7637 5.19461C13.3874 5.5579 13.1092 6.02264 12.9652 6.56593C12.9619 6.58229 12.9553 6.59865 12.9521 6.61502C12.8866 6.84739 12.8408 7.09612 12.8179 7.35468C12.8179 7.3874 12.8113 7.42013 12.8081 7.44958C12.4579 11.1806 10.5433 13.1247 6.84499 13.5076C6.7959 13.5141 6.74681 13.5174 6.69772 13.5239C4.81912 13.6941 3.5918 14.9672 3.60162 16.7378C3.60817 17.6542 3.95508 18.4397 4.55074 19.0059C5.11694 19.6016 5.90242 19.9452 6.81553 19.9518C8.58613 19.9648 9.85926 18.7375 10.0294 16.8557C10.0327 16.8033 10.0393 16.7575 10.0458 16.7084C10.4255 13.0068 12.3728 11.0922 16.1038 10.742C16.1267 10.7388 16.1594 10.7355 16.1889 10.7322Z"
                  fill="white"
                />
                <path
                  d="M25.3608 19.906C25.6521 19.8799 25.9302 19.8275 26.1855 19.7457C26.6404 19.6213 27.0397 19.3988 27.367 19.1042C27.4128 19.0649 27.4586 19.0191 27.5045 18.9766L27.5208 18.9602C28.123 18.358 28.4536 17.5071 28.4045 16.5252C28.3685 15.7856 28.1001 15.1277 27.6714 14.6172C27.5536 14.4732 27.4226 14.3423 27.2786 14.2244C26.7648 13.7924 26.107 13.5273 25.3673 13.4881C24.4313 13.4422 23.6229 13.7368 23.0273 14.2833C22.9945 14.3128 22.9651 14.339 22.9356 14.3684C22.5592 14.7317 22.281 15.1965 22.137 15.7398C22.1338 15.7561 22.1272 15.7725 22.1239 15.7888C22.0585 16.0212 22.0127 16.2699 21.9898 16.5285C21.9898 16.5612 21.9832 16.594 21.9799 16.6234C21.6298 20.3544 19.7152 22.2985 16.0169 22.6814C15.9678 22.6879 15.9187 22.6912 15.8696 22.6978C13.991 22.868 12.7637 24.1411 12.7735 25.9117C12.78 26.8281 13.127 27.6135 13.7226 28.1797C14.2888 28.7754 15.0743 29.119 15.9874 29.1256C17.758 29.1387 19.0311 27.9114 19.2013 26.0295C19.2046 25.9771 19.2111 25.9313 19.2177 25.8822C19.5973 22.1807 21.5447 20.2661 25.2757 19.9159C25.2986 19.9126 25.328 19.9093 25.3608 19.906Z"
                  fill="white"
                />
                <path
                  d="M16.0116 19.9489C17.801 19.9489 19.2517 18.4983 19.2517 16.7088C19.2517 14.9194 17.801 13.4688 16.0116 13.4688C14.2221 13.4688 12.7715 14.9194 12.7715 16.7088C12.7715 18.4983 14.2221 19.9489 16.0116 19.9489Z"
                  fill="white"/>
              </svg>
              <p class="txt-pri mb-0">HODO</p>
            </div>
            <div class="fs-14 fw-500 txt-black pl-3">
              <p>{{ $t("common.lbl_slogan") }}</p>
            </div>
            <div class="w-100-441px">
              <div class="txt-black mb-4 mt-4">
                <p class="fs-32 fw-600">{{ $t("login.lbl_title_login_with_otp") }}</p>
              </div>
              <div class="text-start">
                <div class="mb-3">
                  <input type="text" class="form-control form-input fs-16 fw-500"
                         :placeholder="$t('login.input_text.enter_email_or_phone_number')" required
                         v-model="form_otp.email" @keyup.enter="onSendOtp"/>
                </div>
                <div class="mt-4 mb-24">
                  <button type="button" class="mr-2 btn bg-pri form-btn w-100 fs-16 fw-600 text-white"
                          :disabled="loading || !form_otp.email" @click="onSendOtp">
                    <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
                    {{ $t("login.buttons.get_otp") }}
                  </button>
                </div>
                <a
                  class="txt-pri"
                  href="javascript:;"
                  @click="
                    () => {
                      login_otp = false
                      sendedOtp = false
                    }
                  "
                >{{ $t("login.other_labels.lbl_login_with_password") }}</a
                >
              </div>
            </div>
          </div>

          <div class="w-100-441px">
            <p class="fs-13 fw-500"
               v-html="$t('login.other_labels.lbl_term_and_policy', [`<a class='txt-pri' href='#/dieu-khoan' target='_blank'>${$t('login.other_labels.lbl_term_of_service')}</a>`, `<a class='txt-pri' href='#/chinh-sach' target='_blank'>${$t('login.other_labels.lbl_privacy_policy')}</a>`])"/>
          </div>
        </form>
      </div>
    </div>

    <!-- Forgot Password -->
    <div class="modal fade" id="modal__forgot" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="d-flex align-items-center bg-white modal-header mt-2">
            <h5 class="fs-18 fw-700 txt-black text-left ml-4" id="exampleModalCenterTitle">
              {{ $t("login.lbl_forgot_pwd") }}
            </h5>
            <svg class="cursor-pointer mr-3 mb-2" data-dismiss="modal" width="24" height="25" viewBox="0 0 24 25"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 18.4998L17.9998 6.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M17.9998 18.4998L6 6.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </div>
          <form @submit.prevent="onForgot">
            <div class="modal-body">
              <div class="form-group mb-4">
                <input type="email" required class="form-control form-input fs-16 fw-500 form-input-login"
                       aria-describedby="emailHelp" v-model="form_forgot.email"
                       :placeholder="$t('login.input_text.email')"/>
              </div>
              <!-- <div class="mb-4">
                <vue-recaptcha
                  ref="recaptcha"
                  @verify="onVerify"
                  @expired="onExpired"
                  :sitekey="sitekey"
                  :loadRecaptchaScript="true"
                ></vue-recaptcha>
              </div>-->
              <div class="d-flex justify-content-between">
                <button type="submit" class="btn bg-pri form-btn w-100 fs-16 fw-600 mb-4 text-white"
                        :disabled="loading || !verify_captcha">
                  <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
                  {{ $t("login.buttons.pwd_retrieval") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import Language from '../components/Common/Language.vue'
import {LANGUAGE_CODES, LANGUAGE_KEYS} from '../lang'
import appUtils from '../utils/appUtils'
import {APP_MODE} from '../utils/constants'
import {mixinUser} from '../utils/mixinUser'

export default {
  name: 'LoginPage',
  mixins: [mixinUser],
  components: {VueRecaptcha, Language},
  computed: {},
  data () {
    return {
      sitekey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
      form: {
        username: '',
        password: ''
      },
      error: {
        username: '',
        password: ''
      },
      form_forgot: {
        email: ''
      },
      form_otp: {
        email: '',
        otp: ''
      },
      clinic_id: '',
      token: '',
      loading: false,
      verify_captcha: true,
      login_otp: false,
      sendedOtp: false,
      time_out: 0,
      LANGUAGE_KEYS,
      currentDomain: window.location.hostname,
      show_pw: true
    }
  },
  mounted () {
    let self = this
    window.amplitude.setUserId(null)
    if (appUtils.getLocalUser()) {
      return self.$router.push({path: '/'}).catch(_ => {
      })
    }
    window.amplitude.setUserProperties({
      'User Name': null,
      'User Type': null,
      'Clinic ID': null,
      'Doctor ID': null,
      'Clinic Name': null,
      'Doctor Name': null
    })
    const {cid, token} = self.$route.query
    if (cid && token) {
      self.clinic_id = cid
      self.token = token
    }
    self.sendAmplitude('Open Login Page', {
      Screen: 'Login',
      Timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
      'Root Screen': 'Login'
    })
  },
  methods: {
    onVerify (response) {
      this.verify_captcha = true
    },
    onExpired () {
      this.verify_captcha = false
    },
    resetRecaptcha () {
      this.$refs.recaptcha.reset()
    },
    async onLogin () {
      let self = this

      try {
        self.loading = true
        let resp = await self.$rf.getRequest('AuthRequest').login(self.form)
        console.log(resp)
        if (resp?.data?.access_token) {
          appUtils.setLocalToken(resp?.data?.access_token)

          this.currentDomain = window.location.hostname
          let domain = null
          if (resp?.data?.domain) {
            domain = new URL(resp?.data?.domain)
            domain = domain.hostname
          }

          if (this.currentDomain !== 'localhost' && domain !== null && domain !== this.currentDomain) {
            setTimeout(() => {
              window.location.href = resp?.data?.domain // `http://localhost:8082/#/redirect-login.html/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xMjcuMC4wLjE6ODAwMFwvYXBpXC9hdXRoXC9sb2dpbiIsImlhdCI6MTY4MTU1MjQ3MywiZXhwIjoxNjg0MTQ0NDczLCJuYmYiOjE2ODE1NTI0NzMsImp0aSI6IjNtSjhwMmxadU5LbGpyQ3IiLCJzdWIiOjE3NDEsInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEifQ.5aw_pO_WYdMYvGiJQ7yXfJ84PByFChQBWR51EOkn_o4`
            }, 300)
            return
          }

          resp = await self.$rf.getRequest('AuthRequest').me()
          if (resp) {
            resp = await self.$rf.getRequest('AuthRequest').setLanguageForUser({
              lang: LANGUAGE_CODES?.[self.$lang.toUpperCase()]
            })

            appUtils.setLocalUser(resp?.data)
            appUtils.setAppMode(APP_MODE.workspace)
          }

          self.loading = false

          setTimeout(() => {
            let path = '/'

            if (self.$route.query.redirect) {
              path = '/#' + self.$route.query.redirect
            }
            window.location.href = path
          }, 300)
        }
        window.amplitude.setUserId(resp?.data.id)
        self.sendAmplitude('User Login', {
          Status: 'Success',
          Screen: 'Login',
          'User type': resp ? resp?.data?.roles[0] : '',
          Timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
          error: 'none'
        })
      } catch (e) {
        const errorData = {...e}.response?.data
        if (errorData['Not found']) {
          this.error.password = 'Không tồn tài tài khoản'
        } else if (errorData['Not Match']) {
          this.error.password = 'Sai tài khoản hoặc mật khẩu'
        } else {
          this.error.password = {...e}.response?.data?.message
        }
        self.loading = false
        self.sendAmplitude('User Login', {
          Status: 'Fail',
          Screen: 'Login',
          Timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
          'Root Screen': 'Login',
          Error: e
        })
      }
    },
    onSignUp () {
      window.$router.push('/sign-up')
    },
    onShowModalForgot () {
      window.$('#modal__forgot').modal('show')
    },
    async onForgot () {
      let self = this
      self.loading = true
      if (self.verify_captcha) {
        try {
          let reps = await self.$rf.getRequest('AuthRequest').postForgotGo(self.form_forgot)
          console.log(reps)
          if (reps.data.code === 200023) {
            self.$swal.fire({
              html: `<p class="fs-16 fw-600 txt-grey-900">${this.$t('login.messages.not_exit_user')}</p>`,
              icon: 'error',
              confirmButtonColor: '#20409B',
              confirmButtonText: this.$t('login.buttons.confirm')
            })
            return
          } else {
            self.$swal.fire({
              html: `<p class="fs-16 fw-600 txt-grey-900">${this.$t('login.messages.sent_link_pwd_retrieval_to_email')} ${this.form_forgot.email}</p>`,
              icon: 'success',
              confirmButtonColor: '#20409B',
              confirmButtonText: this.$t('login.buttons.confirm')
            })
          }
        } catch (e) {
          console.log(e)
        } finally {
          self.loading = false
          window.$('#modal__forgot').modal('hide')
          self.resetRecaptcha()
        }
      } else {
        self.$swal.fire({
          html: `<p>${this.$t('login.message_errors.please_validate_captcha')}</p>`,
          icon: 'error'
        })
      }
      self.loading = false
    },
    onDoctorSignUp () {
      this.$router.push({name: 'SignUp'})
    },
    // goToCond () {
    //   window.open('https://hodo.com.vn/#/dieu-khoan')
    // },
    // goToTerm () {
    //   window.open('https://hodo.com.vn/#/chinh-sach')
    // },
    async onSendOtp (e) {
      e.preventDefault()

      let self = this
      if (!this.form_otp.email) {
        alert(this.$t('login.alerts.you_have_not_enter_email'))
        return
      }
      const params = {
        email: this.form_otp.email
      }
      await this.$rf
        .getRequest('AuthRequest')
        .sendOtpEmail(params)
        .then(r => {
          self.sendedOtp = true
          self.time_out = 30
          self.interval_resend = setInterval(self.countDownTimeout, 1000)
        })
    },
    countDownTimeout () {
      this.time_out -= 1
      if (this.time_out === 0) {
        clearInterval(this.interval_resend)
      }
    },
    async onLoginOTP () {
      const self = this
      const params = {
        email: this.form_otp.email,
        otp: this.form_otp.otp
      }
      try {
        let resp = await this.$rf.getRequest('AuthRequest').loginOtp(params)
        if (resp.data.access_token) {
          appUtils.setLocalToken(resp.data.access_token)

          resp = await self.$rf.getRequest('AuthRequest').me()
          if (resp) {
            resp = await self.$rf.getRequest('AuthRequest').setLanguageForUser({lang: self.$lang})

            appUtils.setLocalUser(resp.data)
            appUtils.setAppMode(APP_MODE.workspace)
          }

          self.loading = false

          setTimeout(() => {
            let path = '/'
            if (self.$route.query.redirect) {
              path = '/#' + self.$route.query.redirect
            }
            window.location.href = path
          }, 300)
        }
      } catch (e) {
        self.$toast.open({
          message: this.$t('login.message_errors.otp_is_incorrect'),
          type: 'warning'
          // all other options
        })
        console.log(e)
      }
    },
    onCreateNewOrg () {
      this.$router
        .push({
          name: 'CreateNewOrg'
        })
        .catch(_ => {
        })
    }
  }
}
</script>
<style scoped>
.y100-auto {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.sign-form-wraper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

::-webkit-scrollbar {
  width: 10px;
}

.overflow-hidden {
  overflow: hidden;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-24 {
  margin-bottom: 24px;
}

.form-btn:disabled {
  background-color: #667085;
}

.form-btn {
  border-radius: 12px;
  padding: 12px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.h-100vh {
  height: 100vh;
}

.bg-img-login {
  height: 100vh;
  background-image: url("../../public/assets/images/login/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.form-input {
  color: #1d2939;
  border: 0.1px solid #98a2b3;
  padding: 8px;
  border-radius: 12px;
}

.w-100-441px {
  width: 100%;
  max-width: 441px;
  margin: 0 auto;
}
</style>
